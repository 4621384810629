export const FooterMobileStyles = {
  wrapper: {
    height: '440px',
    backgroundColor: 'black',
    display: 'none',
  },
  footerSection: {
    width: '94%',
    margin: '0px auto',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  footerInfo: {
    width: '100%',
    listStyleType: 'none',
    color: '#808080',
    display: 'flex',
    margin: 0,
    height: '100%',
    alignItems: 'center',
    fontFamily: 'Bitter',
    fontSize: '20px',
    justifyContent: 'space-evenly',
    padding: 0,
  },
  logoPart: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
  infoItem: {
    height: '75%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between',
  },
  currency: {
    display: 'flex',
    width: '80%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  language: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    fontFamily: 'Bitter',
  },
  socialNetworks: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    height: '100px',
    justifyContent: 'space-between',
  },
};
