import { Scale } from '@mui/icons-material';

export const courseStyles = {
  buyBtn: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    height: 142,
    width: 363,
    padding: 0,
    outline: 0,
  },
};
