export const profileStyles = {
  wrapperChild: {
    paddingTop: '150px',
    display: 'flex',
    width: '80%',
    margin: 'auto',
    justifyContent: 'space-between',
  },
  profileTitle: {
    margin: 0,
    color: '#444444',
    fontSize: '83px',
    fontFamily: 'Drum',
    whiteSpace: 'nowrap' as 'nowrap',
  },
  nameSurname: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
    flexDirection: 'column' as 'column',
  },
  nameWrap: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '7px',
  },
  nameLabel: {
    fontFamily: 'Bitter',
    fontSize: '25px',
    paddingLeft: '5px',
  },
  emailWrap: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '7px',
    marginTop: '25px',
  },
  input: {
    width: '590px',
    backgroundColor: 'transparent',
    border: 'none',
    height: '39px',
    fontFamily: 'Bitter',
    color: '#444444',
    fontSize: '16px',
    fontWeight: '600',
  },
  currencyWrap: {
    width: '61%',
    display: 'flex',
    gap: '30px',
    alignItems: 'center',
    position: 'relative' as 'relative',
  },
  coinWrap: {
    margin: '0 50px 0 0',
    display: 'flex',
    alignItems: 'center',
  },
  settTitle: {
    margin: 0,
    color: 'rgb(68, 68, 68)',
    fontSize: '83px',
    fontFamily: 'Drum',
  },
  currBtn: {
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    fontSize: '30px',
    zIndex: 10,
  },
  langBtn: {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'Bitter',
    fontSize: '42px',
    color: '#505050',
    zIndex: 10,
  },
  logoutBtn: {
    border: 'none',
    background: 'transparent',
    color: '#505050',
    cursor: 'pointer',
    fontFamily: 'Bitter',
    fontSize: '30px',
    fontWeight: '600',
  },
  purchCourses: {
    margin: 0,
    color: '#D4D4D4',
    fontSize: '83px',
    fontFamily: 'Drum',
    textAlign: 'center' as 'center',
    paddingTop: '40px',
  },
  langBtnsWrap: {
    display: 'flex',
    gap: '20px',
  },
  cardsWrap: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    // gap: '3rem',
    justifyContent: 'space-between',
    width: '80%',
    margin: 'auto',
  },
};
